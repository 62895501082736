.h-wrapper {
  /* background-color: black; */
  color: white;
  z-index: 99;
}

.h-container {
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--secondary);
}
.h-menu {
  gap: 2rem;
}

.h-menu > *:hover {
  cursor: pointer;
}
/* .h-menu:hover{
    background-color: rgb(148, 145, 145);
} */

a:hover {
  padding: 6px;
  background-color: rgb(4, 62, 42);
  border-radius: 6px;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .h-menu {
    z-index: 99;
    color: black;
    position: absolute;
    top: 3rem;
    right: 4rem;
    background-color: white;
    flex-direction: column;
    font-weight: 500;
    gap: 2rem;
    padding: 3rem;
    border-radius: 10px;
    align-items: flex-start;
    box-shadow: var(--shadow);
    transition: all 300ms ease-in;
  }
}
