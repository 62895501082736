.innner-container {
  gap: 1rem;
  background-color: rgb(19, 19, 107);
  padding: 2rem;
  border-radius: 20px;
  border: 6px solid rgb(148, 148, 249);
  text-align: center;
}

.innner-container .primaryText {
  color: aliceblue;
  font-weight: 600;
}

.innner-container .secondaryText {
  color: rgb(222, 221, 164);
}

.innner-container .button {
  /* font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white; */
  background: black;
  background-color: black;
  border: 2px solid aliceblue;
  box-shadow: var(--shadow);
  border-radius: 10px;
}

.button > a:hover {
  background-color: black;
  transform: scale(1.1);
  border-radius: 10px;
  padding: 0px;
}
